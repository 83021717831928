import React from 'react';
import vector from '../../Assets/images/Vector.png';
import vector1 from '../../Assets/images/Vector (1).png';
import dotwithcircle from '../../Assets/images/entypo_info-with-circle.svg';

import NewsComponent from '../../Components/NewsComponent/NewsComponent';
import { Link } from 'gatsby';
function NewsSection() {
  return (
    <div className="container pt-20">
      <div class="flex items-center mb-6">
    <div class="flex lg:pl-12  items-center w-max">
    <span>
    <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDkiIGhlaWdodD0iNDkiIHZpZXdCb3g9IjAgMCA0OSA0OSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTI0LjUxNjYgMS40ODM3NkMxMS43NzEzIDEuNDgzNzYgMS40NDE1MyAxMS44MTM2IDEuNDQxNTMgMjQuNTU2NEMxLjQ0MTIxIDI3LjU4NjggMi4wMzc4NSAzMC41ODc1IDMuMTk3MzcgMzMuMzg3MkM0LjM1Njg5IDM2LjE4NjkgNi4wNTY1NyAzOC43MzA4IDguMTk5MzUgNDAuODczNkMxMC4zNDIxIDQzLjAxNjQgMTIuODg2IDQ0LjcxNjEgMTUuNjg1OCA0NS44NzU2QzE4LjQ4NTUgNDcuMDM1MSAyMS40ODYyIDQ3LjYzMTggMjQuNTE2NiA0Ny42MzE0QzM3LjI1NyA0Ny42MzE0IDQ3LjU4OTIgMzcuMzAxNiA0Ny41ODkyIDI0LjU1NjRDNDcuNTg5MiAxMS44MTM2IDM3LjI1NyAxLjQ4Mzc2IDI0LjUxNjYgMS40ODM3NlpNMjYuNjcgOS44MTM5NUMyOC45MTk2IDkuODEzOTUgMjkuNTgwNSAxMS4xMTkgMjkuNTgwNSAxMi42MTE1QzI5LjU4MDUgMTQuNDc0MSAyOC4wOTA0IDE2LjE5NzQgMjUuNTQ1MiAxNi4xOTc0QzIzLjQxNTggMTYuMTk3NCAyMi40MDE2IDE1LjEyNzkgMjIuNDY0MSAxMy4zNTY2QzIyLjQ2NDEgMTEuODY0IDIzLjcxMTQgOS44MTM5NSAyNi42NyA5LjgxMzk1WiIgZmlsbD0iIzA2NEU5NSIvPgo8cGF0aCBkPSJNMjAuOTA2NyAzOC4zNzZDMTkuMzY4NSAzOC4zNzYgMTguMjQ2MSAzNy40NDExIDE5LjMyMDUgMzMuMzQzM0wyMS4wODIxIDI2LjA3M0MyMS4zODc0IDI0LjkwOTggMjEuNDM3OCAyNC40NDM1IDIxLjA4MjEgMjQuNDQzNUMyMC42MjMxIDI0LjQ0MzUgMTguNjI1OSAyNS4yNDYyIDE3LjQ0ODIgMjYuMDM5NEwxNi42ODE1IDI0Ljc4MjRDMjAuNDE4OCAyMS42NjA0IDI0LjcxNjEgMTkuODI5IDI2LjU1NDcgMTkuODI5QzI4LjA5MjkgMTkuODI5IDI4LjM0NzYgMjEuNjQ1OSAyNy41ODA5IDI0LjQ0MzVMMjUuNTYyMSAzMi4wODYzQzI1LjIwNCAzMy40MzcgMjUuMzU3OCAzMy45MDMzIDI1LjcxNTkgMzMuOTAzM0MyNi4xNzc0IDMzLjkwMzMgMjcuNjg2NyAzMy4zNDU3IDI5LjE3MiAzMi4xNzUyTDMwLjA0MiAzMy4zNDMzQzI2LjQwNTcgMzYuOTc3MiAyMi40NDI1IDM4LjM3NiAyMC45MDY3IDM4LjM3NloiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=" class="w-8 lg:w-auto"/>
    </span>
    <a class="text-primary font-normal lg:text-3xl text-left  pl-2" href="/newsAndInformation">News And Information</a>
    </div>
    <div class="flex items-center ml-auto mt-3">
    <a class="border border-primary font-medium px-2 rounded-md text-center text-primary" href="/newsAndInformation">For more</a>
</div>
    </div>

      <div className="border-solid border-b-2 border-secondary"></div>

      <div>
        <NewsComponent />
      </div>
    </div>
  );
}
export default NewsSection;
